.blog-post {
  padding: 2rem 0.5rem 1rem;
}

.blog-content {
  padding-top: 1rem;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-in-out;
  }

.blog-post-image img {
  width: 100%;
  height: auto;
}

/* .blog-content {
  padding-top: 1rem;
} */
