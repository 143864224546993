nav {
  position: sticky;
  top: 0;
  z-index: 2;
  margin: 0 auto;
  background-color: #000;
  width: 100%;
}

.navbar-spacing {
  background-color: #000;
  padding: 1rem 0;
  position: relative;
  max-width: 750px;
  margin: 0 auto;
}

.site-title {
  margin: auto;
  text-align: center;
}

.site-title h1 {
  color: white;
  font-family: "MedievalSharp", system-ui;
  font-size: 2.4em;
}

.site-title-link {
  text-decoration: none;
  color: white;
  font-family: inherit;
}

.site-title-link:hover {
  color: inherit;
  text-shadow: inherit;
}

.site-logo {
  width: 3rem;
  height: 3rem;
}

.menu-btn {
  transition: all 0.5s ease-in-out;
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  top: 50%; /* Center vertically */
  right: 0;
  transform: translateY(-50%); /* Adjust for the height of the element */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-btn__burger {
  width: 2.5rem;
  height: 0.2rem;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
  content: "";
  position: absolute;
  width: 2.5rem;
  height: 0.2rem;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}

.menu-btn__burger::before {
  transform: translateY(-16px);
}

.menu-btn__burger::after {
  transform: translateY(16px);
}

.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}

.nav-links-list {
  display: none;
}

/* TABLET */
@media screen and (min-width: 768px) {
  .site-title h1 {
    font-size: 3.5em;
  }

  .navbar-spacing {
    padding: 1.5rem 0;
  }
}

/* DESKTOP */
@media screen and (min-width: 1024px) {
  
  .navbar-spacing {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .site-title {
    display: flex;
    justify-content: flex-start;
    margin: 0;
  }

  .menu-btn {
    display: none;
  }

  .nav-links {
    
  }

  .nav-links-list {
    display: flex;
    min-width: 30rem;
    justify-content: space-between;
  }

  .nav-links-item {
    list-style: none;
    /* margin-right: 5rem; */
  }
}
