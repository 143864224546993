.blog-list-banner {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: url("../../assets/images/bloglist-banner-1280x720.jpg") no-repeat
    center center fixed;
  background-size: cover;
}

@media (max-width: 768px) {
  .blog-list-banner {
    background: url("../../assets/images/bloglist-banner-1280x720.jpg") no-repeat
      center center;
  }
}

.blog-list-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.blog-list-overlay-text {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.blog-list-h1 {
  font-size: 4rem;
  line-height: 4rem;
}

.blurb {
  padding-top: 1rem;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-size: 1.5em;
}

.page-number {
  background: none;
  border: none;
  font-size: 1.2em;
  margin: 0 1rem;
  cursor: pointer;
  color: #999900; /* Dim color for inactive buttons */
  transition: color 0.3s, text-shadow 0.3s;
  font-family: "Bellefair", serif;
}

.page-number.active {
  color: #f8fb04; /* Active button color */
  text-shadow: 0 0 10px #f8fb04; /* Glow effect */
}

.page-number:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
