.home-page {
  /* border: #22fb00 1px solid; */
}

.jumbotron {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: url('../../assets/images/home-page-banner-1280x720.jpg') no-repeat center center fixed; 
  background-size: cover;
}

/* media query */
@media (max-width: 768px) {
  .jumbotron {
    background: url('../../assets/images/home-page-banner-1280x720.jpg') no-repeat center center; 
  }
}

.jumbotron::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
}

.overlay-text {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* text-align: center;
  max-width: none; */
  text-align: left; /* Align text to the left */
  width: 80%; /* Allow more room for the text */
}

.tagline {
  font-family: "Alien League";
  color: #22fb00;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  line-height: normal;
  /* animation: glow 2s ease-in-out infinite; */
}

@keyframes glow {
  0% {
    text-shadow: 0 0 30px #22fb00;
  }
  50% {
    text-shadow: 0 0 150px #ffffff, 0 0 200px #ffffff, 0 0 250px #ffffff,
      0 0 300px #890685;
  }
  100% {
    text-shadow: 0 0 30px #22fb00;
  }
}

.author {
  font-family: "Special Elite", system-ui;
  font-size: 1.3rem;
  color: #ffffff;
}

.latest-release {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 2rem 1rem;
}

.latest-release h1 {
  margin-bottom: 1.5rem;
}

.latest-release-image {
  border: #ffffff 1px solid;
  margin-bottom: 1.5rem;
}

.home-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #22fb00;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: "Special Elite", system-ui;
  color: #000;
}

.date {
  font-size: 0.8rem;
  font-family: "Special Elite", system-ui;
  margin-bottom: 0.2rem;
}

/* TABLET */
@media (min-width: 768px) {
  .jumbotron .overlay-text {
    padding-top: 5rem;
  }

  .overlay-text {
    text-align: center;
    max-width: none;

  }

  .tagline {
    font-size: 4rem;
  }

  .author {
    font-size: 1.5rem;
  }

  .latest-release-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .date {
    font-size: 1rem;
  }
}

/* DESKTOP */
/* @media (min-width: 1024px) {
  
} */

/* REAL BIG DESKTOP */
@media (min-width: 1500px) {
  .jumbotron .overlay-text {
    padding-top: 5rem;
  }

  .tagline {
    font-size: 4rem;
  }

  .author {
    font-size: 2rem;
  }
}
