.books-banner {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: url("../../assets/images/books-page-creature-2-1157x720.jpg") no-repeat
    center center fixed;
  background-size: cover;
}

@media (max-width: 768px) {
  .books-banner {
    background: url("../../assets/images/books-page-creature-2-1157x720.jpg") no-repeat
      center center;
  }
}

.books-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.books-overlay-text {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.books-overlay-text h1 {
  font-size: 4rem;
  line-height: 0.9;
}

.book-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
}

.book-cover {
  width: 200px;
  height: auto;
}
