.podcast-ad-banner {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  background: url("../../assets/images/podcast-banner-1280x866.jpg") no-repeat
    center center fixed;
  background-size: cover;
}

@media (max-width: 768px) {
  .podcast-ad-banner {
    background: url("../../assets/images/podcast-banner-1280x866.jpg") no-repeat
      center center;
  }
}

.podcast-ad-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.podcast-overlay-text {
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.podcast-overlay-text h1 {
  font-size: 2.8rem;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.podcast-overlay-text h2 {
  font-family: "Special Elite", system-ui;
  color: white;
  margin-bottom: 0.5rem;
}

.subscribe-button {
  background-color: #f8fb04;
  color: #000;
  font-family: "Special Elite", system-ui;
  font-size: 1.5rem;
  padding: 0.75rem 1rem 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.subscribe-button:hover {
  transform: scale(1.1);
}

.subscribe-button:active {
  transform: scale(0.9);
}

/* TABLET */
@media (min-width: 768px) {
  .podcast-overlay-text h1 {
    font-size: 4rem;
  }

  .subscribe-button {
    font-size: 2rem;
  }
}
