footer {
  border-top: 1px solid #fff;
  position: fixed;
  bottom: -150px;
  left: 0;
  width: 100%;
  transition: bottom 0.5s ease-in-out;
  z-index: 1000;
  background-color: #000;
  height: 5.7rem;
}

.footer-wrapping {
  max-width: 750px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.footer-visible {
  bottom: 0;
}

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}

.footer-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #22fb00;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: "Special Elite", system-ui;
  color: #000;
}

.footer-button:hover {
  background-color: #156109;
  color: #fff;
}

.copyright p {
  font-family: "Special Elite", system-ui;
  margin: 0 0;
  font-size: 0.7rem;
}

/* share modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 1.25rem;
  border-radius: 0.625rem;
  width: 18.75rem;
  text-align: center;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
  color: #000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  cursor: pointer;
  border: none;
  background-color: #fff;
  border-radius: 0.3125rem;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ccc;
}

.modal-content h3 {
  font-family: "Alien League";
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
}

.modal-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  margin-bottom: 1.25rem;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-buttons img {
  cursor: pointer;
}

.modal-buttons img:hover {
  /* make element expand */
  transform: scale(1.3);
}

.modal-button {
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  background-color: #22fb00;
  border-radius: 0.3125rem;
  transition: background-color 0.3s ease;
  font-family: "Special Elite", system-ui;
  color: #000;
  border: 0.1rem solid black;
  margin-bottom: 1.25rem;
}

.modal-button:hover {
  background-color: #156109;
  color: #fff;
}

.clipboard-button {
  margin-bottom: 1.25rem;
  border: 0.1rem solid black;
}

.modal-input {
  width: 100%;
  padding: 0.625rem;
  border: 0.1rem solid black;
  border-radius: 0.3125rem;
  margin-bottom: 1.25rem;
}

.subscribe-message {
  min-height: 1.5rem;
}


/* DESKTOP */
@media screen and (min-width: 1024px) {
  .footer-wrapping {
    max-width: 900px;
  }
}
