.burger-menu {
  background-color: #373436;
  transition: transform 0.4s;
  transform: translateX(-100vw, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 40vw;
  height: 100vh;
  display: block;
  z-index: 3;
}

.burger-menu-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    list-style: none;
    background-color: #373436;
}

.burger-menu-nav-item {
    padding: 1rem 0;
    font-size: 3rem;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    text-decoration: none;
    text-align: center;
}

.suggested-reading{
  line-height: 0.5;
}

.visible {
  transform: translate3d(-0vw, 0, 0);
}

.hidden {
  transform: translate3d(-40vw, 0, 0);
}

/* TABLET */
@media (min-width: 768px) {
  .burger-menu-nav-item {
    padding: 2rem 0;
  }

  .burger-menu-nav-item a {
    font-size: 2rem; /* Reset the font size for tablet devices */
  }

  .hidden {
    transform: translate3d(-40vw, 0, 0); /* Reset the translateX value for tablet devices */
  }
}


